@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";
@import "../../../styles/fx";
@import "../../../styles/placeholders/";
@import '../../../styles/globalVariables';

.toastPopup {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  align-items: center;
  top: 88px;
  z-index: 9999;
  margin: 0;
  transition: right 1s;
  backface-visibility: hidden;
  background: #10a26f;

  &.success {
    background-color: #10a26f;
  }
  &.warning {
    background-color: #f8b756;
  }
  &.error {
    background-color: #ee5441;
  }
  &.new_bid {
    background-color: #636569;
  }
  &.time_extend {
    background-color: #f8b756;
  }
  .icon {
    width: 28px;
    margin-right: 8px;
    line-height: 0px;
    svg {
      width: 28px !important;
      height: 28px !important;
    }
  }
  .popupText {
    width: calc(100% - 40px);
  }
  .headerText {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    padding-bottom: 4px;
    html[dir='rtl'] & {
      font-family: $arabic-font;
    }
  }
  .descriptiveText {
    font-family: $primary-font;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
      html[dir='rtl'] & {
      font-family: $arabic-font;
    }
  }
  @media only screen and (max-width: 1280px) {
    max-width: 500px;
  }
  @include for-size(tablet-phone) {
    top: 9px;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 98%;
  }
  @media only screen and (max-width: 640px) {
    min-width: 91.5%;
    max-width: 95%;
  }
}

html[dir="rtl"] {
  .toastPopup {
    right: auto !important;
    z-index: 9999;
    transition: left 1s;
    .icon {
      margin-right: 0px;
      margin-left: 10px;
    }
  }
}

.color_black {
  color: #191919 !important;
}
.toastWrapWhenSingle {
  width: 100%;
  transition: 0.3s;
  z-index: 101;
  @include for-size(phone-only) {
    width: 100%;
    left: 0;
  }

  html[dir="rtl"] {
    left: -200%;
    right: inherit;
  }
}
