@import '../styles/globalVariables';
@import '../styles/mixins';
// .mapboxgl-ctrl-group {
//   margin: 10px 0px 0px 24px !important;
//   float: left !important;
// }
[dir="rtl"] .mapboxgl-ctrl-bottom-right{
  right: unset !important;
  left: 0 !important;
}

[dir="rtl"] .mapboxgl-ctrl-group {
  margin: 10px 0px 0px 10px !important;
  float: left !important;
}


.mapboxgl-ctrl-group {
  box-shadow: 0px 10px 19.052px rgba(161, 168, 191, 0.35), 0px 2.54027px 2.54027px rgba(0, 0, 0, 0.1) !important;
  border-radius: 12px !important;
  margin-right:24px !important;
  float: right !important;
}
.mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-in,
.mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-out {
  background: #ffffff !important;
  width: 36px !important;
  height: 32px !important;
  /* width: 36px !important;
    height:32px !important; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-in {
  border-radius: 12px 12px 0 0 !important;
}

.mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
  background-image: url('../../public/images/zoomIn.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-out {
  border-radius: 0px 0px 12px 12px !important;
  border-top: 1px solid rgba(161, 168, 191, 0.5) !important;
}

.mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url('../../public/images/zoomOut.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

[dir=ltr] div#aucMapbox div#centerMe {

  right: 24px;
  bottom: 110px;
  @include for-size(tablet-phone) {
    right: 25px;
    bottom: 24px;
  }
}
@include for-size(phone-only)  {
  .mapboxgl-ctrl-bottom-right{
    display: none;
  }  
}
#aucMapbox canvas.mapboxgl-canvas{
  border-radius: 8px !important;
}