/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73oDd4iYl.woff2") format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73ord4iYl.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73oTd4g.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73oDd4iYl.woff2") format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73ord4iYl.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73oTd4g.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73oDd4iYl.woff2") format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73ord4iYl.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73oTd4g.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73oDd4iYl.woff2") format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73ord4iYl.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73oTd4g.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73oDd4iYl.woff2") format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73ord4iYl.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73oTd4g.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73oDd4iYl.woff2") format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* Jost latin-ext */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73ord4iYl.woff2") format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* Jost latin */
@font-face {
    font-family: 'Jost';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/92zatBhPNqw73oTd4g.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* arabic */
@font-face {
    font-family: 'Noto Kufi Arabic';
    font-style: normal;
    font-weight: 100 900;
    font-display: swap;
    src: url("https://cdn.wasalt.sa/fonts_fe/CSRk4ydQnPyaDxEXLFF6LZVLKrodrOYFFkCqIzAUWw.woff2") format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0897-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC, U+102E0-102FB, U+10E60-10E7E, U+10EC2-10EC4, U+10EFC-10EFF, U+1EE00-1EE03, U+1EE05-1EE1F, U+1EE21-1EE22, U+1EE24, U+1EE27, U+1EE29-1EE32, U+1EE34-1EE37, U+1EE39, U+1EE3B, U+1EE42, U+1EE47, U+1EE49, U+1EE4B, U+1EE4D-1EE4F, U+1EE51-1EE52, U+1EE54, U+1EE57, U+1EE59, U+1EE5B, U+1EE5D, U+1EE5F, U+1EE61-1EE62, U+1EE64, U+1EE67-1EE6A, U+1EE6C-1EE72, U+1EE74-1EE77, U+1EE79-1EE7C, U+1EE7E, U+1EE80-1EE89, U+1EE8B-1EE9B, U+1EEA1-1EEA3, U+1EEA5-1EEA9, U+1EEAB-1EEBB, U+1EEF0-1EEF1;
}

:root {
    --arabic-font: 'Noto Kufi Arabic', sans-serif;
}

.skeleton_on {
  background-image: linear-gradient(#ECECF0, #D9D9E0);
  border-radius: 4px;
  color: transparent !important;
  position: relative;
  width: fit-content;
  border: none !important;
  &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(#ECECF0, #D9D9E0);
      border-radius: 4px;
      z-index: 1;
      left: 0;
      top: 0;
  }
}
.skeleton_auction_timer {
  background-image: linear-gradient(#ECECF0, #D9D9E0);
  .bigColoredText{
      background-image: linear-gradient(#ECECF0, #D9D9E0);
  border-radius: 4px;
  color: transparent !important;
  }
  div[aria-label="Countdown timer"] {
      background: #acacac;
      border-radius: 50%;
      svg{
          opacity: 0;
      }
  }
}
.skeleton_spacing{
  margin-bottom: 4px !important;
}
.skeleton_full_width{
  width: 100%;
}

.tooltipAdpBid{
    position: absolute;
    inset: 0px auto auto 60px !important;

}
@media (max-width:768px){
    .tooltipAdpBid{
        position: absolute;
        inset: 0px auto auto -60px !important;
    
    }  
    .tooltipAdpBid .tooltip_tooltipArrow{
        position: absolute;
        left: 60px !important;
        transform: translate3d(143.2px, 0px, 0px);
    } 
}
.tooltipAdpBid .tooltip_tooltipArrow{
    position: absolute;
    left: -60px !important;
    transform: translate3d(143.2px, 0px, 0px);
}
.tab__close {
  display: flex;
  color: #191919;
  background: #fff;
  cursor: pointer;
  border-radius: 4px;
  justify-content: flex-end;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
}

/*------------------------GDP CARD SWIPER-------------------*/



.myGdpSwiper{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    height:257px;
}

[dir='rtl'] .myGdpSwiper {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

@media (max-width:786px) {
    .myGdpSwiper{
        border-radius: 8px;
        height:180px !important;
    } 
    [dir='rtl'] .myGdpSwiper {
        border-radius: 8px;
        height:180px !important;
    }
}


.myGdpSwiper .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
left:50% !important;
transform: translateX(-50%) !important;
display: flex !important;
align-items: center;
justify-content: center;
}
.myGdpSwiper .swiper-pagination-bullet{
background:#fff !important;
opacity: 1;
width:6px;
height: 6px;
}

.myGdpSwiper .swiper-pagination-bullet-active{
    border:2px solid #fff;
background: rgba(0, 0, 0, 0.44) !important;
width:12px;
height: 12px;
}

.myGdpSwiper> .swiper-pagination{
    /* display: block !important; */
    width: auto !important;
}

.photoGallery> .swiper-pagination{
    display: none !important;
}

/* Make the PDF content fit the device width on mobile */
.rpv-core__canvas-layer{
    height: auto !important; 
    margin: auto;
}

.viewDocPdf .rpv-core__inner-page{
    background: #F3F6FA;  
}
/* space not on first page*/
.viewDocPdf .rpv-core__inner-page:not([aria-label="Page 1"]){
    padding-top: 5px;
}
.viewDocPdf .rpv-core__inner-page-container{
    margin-top: 24px;
}
@media screen and (max-width: 992px) {
    .viewDocPdf .rpv-core__inner-page-container{
        margin-top: 16px;  
    }
  }

.viewDocPdf div[data-testid^="core__inner-current-page"]:last-child {
    margin-bottom: 24px; 
}

.viewDocPdf .rpv-core__page-layer {
    width: calc(100% - 80px) !important;
    height: auto !important;
}