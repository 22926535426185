@import '../styles/globalVariables';
@import '../styles/mixins';

#international-carousel {
  max-width: 100%;
  margin: 0 auto;

  .swiper-slide {
    transform: scale(0.8) translate3D(0, 0, 0);
    transition: transform 0.3s ease-in-out;
    cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODgiIGhlaWdodD0iODgiIHZpZXdCb3g9IjAgMCA4OCA4OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNDQiIGN5PSI0NCIgcj0iNDIuNSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIi8+CjxwYXRoIGQ9Ik0zNCA0NEg0MiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTM4IDQ4TDM0IDQ0TDM4IDQwIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNTQgNDRINDYiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik01MCA0OEw1NCA0NEw1MCA0MCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==')
        16 16,
      pointer;
  }
  .swiper-slide-active {
    transform: scale(1) translate3d(0, 0, 5px);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }
  .swiper-slide-prev {
    transform: scale(0.8) translate3D(30%, 0, 0);

    html[dir='rtl'] & {
      transform: scale(0.8) translate3D(-30%, 0, 0);
    }
  }

  .swiper-slide-next {
    transform: scale(0.8) translate3D(-30%, 0, 0);
    html[dir='rtl'] & {
      transform: scale(0.8) translate3D(30%, 0, 0);
    }
  }

  .swiper-coverflow {
    height: 50vw;
    @media screen and (max-width: 1280px) {
      height: 60vh;
    }
  }

  .swiper-wrapper {
    height: 40vw;
    @media screen and (min-width: 480px) and (max-width: 768px) {
      height: 50vh;
    }
    @include for-size(phone-only) {
      height: 50vh;
    }
  }

  .swiper-horizontal > .swiper-scrollbar {
    width: 42vw;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

#curated-carousel {
  .swiper-slide {
    transform: scale(0.5) translate3D(0, 0, 0);
    transition: transform 0.3s ease-in-out;
    cursor: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODgiIGhlaWdodD0iODgiIHZpZXdCb3g9IjAgMCA4OCA4OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iNDQiIGN5PSI0NCIgcj0iNDIuNSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIzIi8+CjxwYXRoIGQ9Ik0zNCA0NEg0MiIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTM4IDQ4TDM0IDQ0TDM4IDQwIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNNTQgNDRINDYiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik01MCA0OEw1NCA0NEw1MCA0MCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==')
        16 16,
      pointer;
  }
  .swiper-slide-active {
    transform: scale(1) translate3d(0, 0, 5px);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  .swiper-slide-prev {
    transform: scale(0.6) translate3d(-20%, 20%, 0);
    @include for-size(tablet-portrait-up) {
      transform: scale(0.6) translate3d(20%, 20%, 0);
    }
    @include for-size(phone-only) {
      transform: scale(0.6) translate3d(40%, 20%, 0);
    }

    html[dir='rtl'] & {
      transform: scale(0.6) translate3d(20%, 20%, 0);
      @include for-size(tablet-portrait-up) {
        transform: scale(0.6) translate3d(-20%, 20%, 0);
      }
      @include for-size(phone-only) {
        transform: scale(0.6) translate3d(-40%, 20%, 0);
      }
    }
  }

  .swiper-slide-next {
    transform: scale(0.6) translate3d(20%, -20%, 0);

    @include for-size(tablet-portrait-up) {
      transform: scale(0.6) translate3d(-20%, -20%, 0);
    }
    @include for-size(phone-only) {
      transform: scale(0.6) translate3d(-40%, -20%, 0);
    }

    html[dir='rtl'] & {
      transform: scale(0.6) translate3d(-20%, -20%, 0);

      @include for-size(tablet-portrait-up) {
        transform: scale(0.6) translate3d(20%, -20%, 0);
      }
      @include for-size(phone-only) {
        transform: scale(0.6) translate3d(40%, -20%, 0);
      }
    }
  }

  .swiper-slide-next + .swiper-slide {
    transform: scale(0.5) translate3d(0, 0, 0);
  }
}

.MuiDrawer-paper {
  width: 750px;
  z-index: 99 !important;
}

.similarPropertiesSwiper {
  --swiper-pagination-color: #636569;
  padding-bottom: 100px !important;

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: 0;
    width: 48px;
    height: 48px;
    margin-top: -webkit-calc(0px - (var(--swiper-navigation-size) / 2));
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: -moz-box;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    color: #fff;
    background-color: #636569;

    &::after {
      font-size: 16px;
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    left: 64px;
  }

  @include for-size(phone-only) {
    .swiper-button-next,
    .swiper-button-prev {
      display: none;
    }
  }
}

.swiper-wrapper {
  @include for-size(phone-only) {
    align-items: center;
  }
}

#pdPhotos {
  .swiper {
    @media screen and (max-width: 767px) {
      /* reduce the image container height on mobile*/
      height: 280px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-color: rgba(50, 51, 53, 0.5);
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    &:after {
      font-size: 16px;
    }
  }
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

.swiper-pagination {
  display: none;
  width: 75px !important;
  //overflow: visible !important;
  @include for-size (tablet-phone) {
    display: block;
  }
  &.swiper-pagination-bullets-dynamic {
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.2);
      vertical-align: middle;
      opacity: 1;
      // left: auto !important;
      // transform: scale(1);
      // html[dir='rtl'] & {
      //  right: auto !important;
      // }
      // &:nth-child(2), &:nth-last-child(2) {
      //   transform: scale(1);
      // }
      &.swiper-pagination-bullet-active-next, &.swiper-pagination-bullet-active-prev {
        transform: scale(1) !important;
      }
      &.swiper-pagination-bullet-active-next-next, &.swiper-pagination-bullet-active-prev-prev {
        transform: scale(0.65) !important;
      }
    }
    .swiper-pagination-bullet-active {
      width: 12px;
      height: 12px;
      background: rgba(0, 0, 0, 0.4);
      border: 2px solid #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25), 0px 3px 5px rgba(0, 0, 0, 0.05);
      &.swiper-pagination-bullet-active-main {
        transform: scale(1) !important;
      }
    }
  }
}

