// Custom reset CSS
@import '../_globalVariables.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: $primary-font;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}
body{
  background: #fff !important;
  width: 100%;
}

[dir='ltr'] {
  font-family: $primary-font;
}

html[lang='ar'],
html[dir='rtl'],
[dir='rtl'] {
  font-family: $arabic-font;
}

body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: inherit;
  font-weight: inherit;
}
/* HTML5 display-role reset for older browsers */

ul,
li,
ol,
dt,
dl {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.modal-open {
  overflow-y: hidden;
}

a {
  &,
  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
  }
}

button,
input,
textarea,
select {
  font-family: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.globalContainer {
  width: 100%;
  max-width: 1256px;
  display: block;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  @media (max-width:767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  //margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(111, 16, 162, 0.15);
  border-right: 1.1em solid rgba(111, 16, 162, 0.15);
  border-bottom: 1.1em solid rgba(111, 16, 162, 0.15);
  border-left: 1.1em solid #6F10A2;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.tippy-tooltip {
  padding: 10px 16px !important;
  background-color: #636569 !important;
  border-radius: 4px !important;
  font-weight: 500 !important;
  font-size: 13px !important;
  line-height: 20px !important;
  color: #FFFFFF !important;
  text-align: left  !important;
  transform: translateY(0px) !important;
  width: 290px;
  html[dir='rtl'] & {
    text-align: right  !important;
  }
}
.tippy-popper[x-placement^=top] [data-animation=shift] {
  top: -10px !important;
}
.tippy-popper[x-placement^=bottom] [x-arrow].arrow-big {
  border-bottom: 12px solid #636569 !important;
  border-right: 8px solid transparent !important;
  border-left: 8px solid transparent !important;
}
.tippy-popper[x-placement^=top] [x-arrow].arrow-big {
  border-top: 12px solid #636569 !important;
  border-bottom: none !important;
  border-right: 8px solid transparent !important;
  border-left: 8px solid transparent !important;
}
.Toastify__toast--error, .Toastify__toast--success{
  font-family: $primary-font;
    html[dir='rtl'] & {
      font-family: $arabic-font;
    }
}

.Toastify__toast--oops{
  background-color: #f8b756;
  button {
    color: #191919 !important;
    opacity: 1;
  }
}



.Toastify__toast-container--top-left, .Toastify__toast-container--top-right{
  z-index: 99999; //Added this z-index, so it can be visible above popup.
  top: 88px;
  right: calc((100% - 1232px) / 2);
  html[dir = 'rtl'] & {
    right: auto;
    left: calc((100% - 1232px) / 2);
  }
  @media only screen and (max-width: 1440px) {
    right: calc((100% - 1132px) / 2);
    html[dir = 'rtl'] & {
      right: auto;
      left: calc((100% - 1132px) / 2);
    }
  }
  @media only screen and (max-width: 1280px) {
    right: calc((100% - 1172px) / 2);
    html[dir = 'rtl'] & {
      right: auto;
      left: calc((100% - 1172px) / 2);
    }
  }
  @media only screen and (max-width: 1270px) {
    right: 42px;
    html[dir = 'rtl'] & {
      right: auto;
      left: 42px;
    }
  }
  @media only screen and (max-width: 1024px) {
    right: 12px;
    html[dir = 'rtl'] & {
      right: auto;
      left: 12px;
    }
  }
  @media only screen and (max-width:767px) {
    top: 9px;
    left: 0px;
    right: 0px;
    html[dir = 'rtl'] & {
      left: 0px;
      right: 0px;
    }
  }
}
.Toastify__toast-container--top-center {
  z-index: 99999; //Added this z-index, so it can be visible above popup.
  top: 88px;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width:767px) {
    transform: none !important;
  
  html[dir='rtl'] &{
    right:auto;
    transform: translateX(50%);
  }
}
  .customToast{
    right: 50%;
    transform: translateX(-50);
    @media only screen and (max-width: 1440px) {
        left:auto !important;
    }
    left: auto !important;
    // right: calc((100% - 500px) / 2) !important;
    // left: calc((100% - 500px) / 2);
  }
  @media (min-width:767px) {
     html[dir = 'rtl'] & {
    right: 50%;
    transform: translate(50%);
  }
  }
 
  // @media only screen and (max-width: 1440px) {
  //   right: calc((100% - 1132px) / 2);
  //   html[dir = 'rtl'] & {
  //     right: auto;
  //     left: calc((100% - 1132px) / 2);
  //   }
  // }
  // @media only screen and (max-width: 1280px) {
  //   right: calc((100% - 1172px) / 2);
  //   html[dir = 'rtl'] & {
  //     right: auto;
  //     left: calc((100% - 1172px) / 2);
  //   }
  // }
  // @media only screen and (max-width: 1270px) {
  //   right: 42px;
  //   html[dir = 'rtl'] & {
  //     right: auto;
  //     left: 42px;
  //   }
  // }
  // @media only screen and (max-width: 1024px) {
  //   right: 12px;
  //   html[dir = 'rtl'] & {
  //     right: auto;
  //     left: 12px;
  //   }
  // }
  @media only screen and (max-width:767px) {
    top: 9px;
    left: 0px;
    right: 0px;
    html[dir = 'rtl'] & {
      left: 0px;
      right: 0px;
    }
  }
  .customClasseng{
    @media  (min-width:767px) {
      border-radius: 12px;
      min-width: 359px;
      right: 1.4em !important;
    }
  
  }
  .Toastify__toast--outbid {
    border-radius: 12px;
    min-width: 359px;
    left: -1em !important;
  
    html[dir='rtl'] & {
      right: -1.5em;
    }
    @media (max-width:767px) {
      min-width: auto;
      left: auto !important;
      right: auto;
      margin: 0px 16px;
      margin-bottom: 9px;
      html[dir='rtl'] & {
        right: auto;
      }
    }
  }
}
.Toastify__toast--withdraw-update-success,
.Toastify__toast--bid-success,
.Toastify__toast--new-bid,
.Toastify__toast--time-extend,
.Toastify__toast--oops,
.Toastify__toast--outbid,
.Toastify__toast--bidder-toast {
  border-radius: 12px;
  min-width: 359px;
  right: 3em;

  html[dir='rtl'] & {
    left: 3em;
    right: auto;
  }
  @media (max-width:767px) {
    min-width: auto;
    left: auto !important;
    right: auto;
    margin: 0px 16px;
    margin-bottom: 9px;
  }
}
.Toastify__toast--withdraw-update-success {
  background-color: #10a26f;
}
.Toastify__toast--bid-success {
  background-color: #10a26f;
}
.Toastify__toast--new-bid {
  background-color: #636569;
}
.Toastify__toast--time-extend {
  background-color: #f8b756;
}
.Toastify__toast--outbid {
  background-color: #ee5441;
}
.Toastify__toast--bidder-toast {
  background-color: #E6F2ED;
  @include for-size(phone-only){
    margin: 0 16px;
    border-radius: 8px;
  }
  .Toastify__close-button{
    color: #485661;
    align-self: center;
    opacity: 1;
  }
}
.Toastify__toast--bidder-toast-partial {
  border: 1px solid #FFE3AB;
  background: #FFFAF0;
  min-height: auto;
  @include for-size(phone-only){
    margin: 0 16px;
    border-radius: 8px;
  }
  .Toastify__close-button{
    color: #485661;
    align-self: center;
    opacity: 1;
  }
}

button {
	-webkit-tap-highlight-color: transparent;
}
div {
	-webkit-tap-highlight-color: transparent;
}

